<template>
    <div class="body">
        <img src="../assets/showpics/trio.png" width="80%" @click="() => { showModal = true }" class="bio-pic" :class="{ 'dark': darkMode }">
        <h1>OUR NEWSLETTER</h1>
        <div class="contact" :class="{ 'dark': darkMode }">
            <p>SIGN UP <a href="https://docs.google.com/forms/d/e/1FAIpQLSfHxEeaSjb-XVy5Xl508xpjP7ie3eP7DHGGchB69J2Ts_xBHw/viewform?usp=sharing" target="_blank"
                    rel="noreferrer noopener">HERE</a></p>
        </div>
        <h1>CONTACT</h1>
        <div class="contact" :class="{ 'dark': darkMode }">
            <p>EMAIL: <a href="mailto:PROSPERITYGOSPEL@FUZZ.SCIENCE">PROSPERITYGOSPEL@FUZZ.SCIENCE</a></p>
            <p>AMPWALL: <a href="https://ampwall.com/a/prosperity-gospel/" target="_blank"
                    rel="noreferrer noopener">https://ampwall.com/a/prosperity-gospel</a></p>
            <p>BANDCAMP: <a href="https://prosperity-gospel.bandcamp.com/" target="_blank"
                    rel="noreferrer noopener">https://prosperity-gospel.bandcamp.com</a></p>
        </div>
        <PhotoModal v-if="showModal" @close="showModal = false"><img src="../assets/showpics/trio.png"></PhotoModal>

    </div>
</template>

<script>
import PhotoModal from './PhotoModal.vue'


export default {
    name: 'LandingComp',
    props: ['darkMode'],
    components: {
        PhotoModal
    },
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
    }

}
</script>

<style scoped>
.contact {
    border: solid black 2px;
    width: 60%;
    padding: 10px;
    background-color: mistyrose;
    margin: auto;
    overflow-wrap: break-word;
}
.contact.dark{
    background-color: #4b6c8e;
    border-color: white;
}
.body {
    width: 80%;
    margin: auto;
    max-width: 900px;
}
</style>